<template>
  <div v-show="opened" class="z-51">
    <div class="fixed inset-0" @click="closeDropdown()"></div>
    <div class="fixed right-5 top-10 z-51 w-80 bg-gray-900 pr-2">
      <div
        class="flex items-center justify-between space-x-2 rounded-tl rounded-tr border-l border-r border-t border-gray-700 bg-gray-800 px-4 py-1"
        id="chat_right_sidebar"
      >
        <div
          class="w-full cursor-pointer text-xs font-medium leading-4"
          @click="
            setChatSidebarState();
            closeDropdown();
          "
        >
          <span>Messenger</span>
        </div>
        <div class="flex items-center space-x-2">
          <div class="cursor-pointer text-gray-300">
            <PencilSquareIcon
              class="h-4 w-4 text-gray-400 hover:text-white"
              @click="
                $emitter.$emit('show:create-channel-modal');
                $emitter.$emit('toggle-chat-dropdown', false);
              "
            />
          </div>
          <div @click="setChatSidebarState, closeDropdown()">
            <XMarkIcon class="h-4 w-4 cursor-pointer text-gray-400 hover:text-white" />
          </div>
        </div>
      </div>
      <div class="z-51 h-full border-b border-l border-r border-gray-700">
        <div v-if="!channelsLoaded" class="h-full">
          <LoadTemplatesMiniMessenger />
        </div>
        <div class="h-full" v-show="channelsLoaded">
          <ChatMain :external-chat="true" />
        </div>
      </div>
      <div class="relative h-auto w-full rounded-b border-b border-l border-r border-gray-700 py-3">
        <div
          class="cursor-pointer text-center text-xs font-medium uppercase tracking-wider text-gray-400 hover:text-white"
          @click="openMessenger(), closeDropdown()"
        >
          SEE ALL IN MESSENGER
        </div>
      </div>
    </div>
  </div>
  <div v-show="selectedChannels.length">
    <div v-for="channel in selectedChannels" :key="channel.cid">
      <div class="hidden">
        <div
          class="chatTile fixed bottom-0 z-51 w-80 cursor-pointer bg-gray-900 text-xs font-medium leading-4 text-white"
          :class="chatOpened(channel.cid) ? '' : 'h-9'"
          :id="`chat_tile_${channel.cid}`"
        >
          <div
            class="flex items-center justify-between space-x-2 rounded-tl rounded-tr border-l border-r border-t border-gray-700 bg-gray-800 pb-2 pl-3 pr-2 pt-2"
            @click.self="setChatOpenState(channel.cid)"
          >
            <div class="flex items-center space-x-2">
              <BaseTooltip>
                <template #target>
                  <span class="text-xs font-medium leading-4 text-gray-100" @click.self="setChatOpenState(channel.cid)">
                    {{ truncate(channelName(channel), 30) }}
                  </span>
                </template>
                <template #default v-if="channelName(channel).length > 30">{{ channelName(channel) }}</template>
              </BaseTooltip>
              <div
                v-if="channel.data.direct_messaging"
                class="ml-1.5 h-2 w-2 rounded-full"
                :class="
                  streamUser &&
                  Object.values(channel.state.members).filter(x => x.user_id != streamUser.id)[0].user.online
                    ? 'bg-green-600'
                    : 'border border-gray-400'
                "
              ></div>
            </div>
            <div class="flex items-center space-x-1">
              <ArrowTopRightOnSquareIcon
                class="h-3.5 w-3.5 text-gray-400 hover:text-white"
                @click="openMessaging(channel.id)"
              />
              <div
                @click="
                  setChatOpenState(channel.cid);
                  $emit('click');
                "
              >
                <ChevronDownIcon
                  class="duration-0/ ml-1 h-5 w-5 transform cursor-pointer text-gray-400 ease-in-out hover:text-white"
                  :class="chatOpened(channel.cid) ? 'rotate-360' : 'rotate-180'"
                />
              </div>
              <XMarkIcon class="h-4 w-4 text-gray-400 hover:text-white" @click="closeChat(channel.cid)" />
            </div>
          </div>
          <div class="h-full rounded-b border-l border-r border-gray-700">
            <ChatChannel
              :ref="`channel_${channel.cid}`"
              :external="true"
              :channel="channel"
              :key="channel.cid"
              @show:gifs="showGifSelector = showGifSelector == $event ? '' : $event"
            />
            <GIFSelection
              v-if="showGifSelector == channel.id"
              class="absolute bottom-0 left-1 mb-1"
              style="margin-bottom: 45px"
              :channel-id="channel.id"
              :external="true"
              @message:send="
                sendMessage($event, channel.cid);
                showGifSelector = '';
              "
              @close="showGifSelector = ''"
            ></GIFSelection>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, onMounted, onBeforeUnmount, watch, computed } from 'vue';
import ChatMain from './ChatMain.vue';
import ChatChannel from './ChatChannel.vue';
import GIFSelection from './ChatGIFSelection.vue';
import { useMapGetter } from '@/store/map-state';
import LoadTemplatesMiniMessenger from '@/components/load_templates/LoadTemplatesMiniMessenger.vue';
import { getChannelName } from '@/composeables/chat';
import { truncate } from '@/composeables/filters';
import { router } from '@inertiajs/vue3';
import useEmitter from '@/composeables/emitter';
import { PencilSquareIcon, ChevronDownIcon, XMarkIcon, ArrowTopRightOnSquareIcon } from '@heroicons/vue/20/solid';
const { streamUser, channels, channelsLoaded } = useMapGetter();

const $emitter = useEmitter();

// OPENED
const opened = ref(false);
onMounted(() => {
  $emitter.$on('toggle-chat-dropdown', data => {
    opened.value = data;
  });
});
function setChatSidebarState() {
  opened.value = !opened.value;
}

// CHANNELS
const selectedChannels = ref([]);
const openedChannels = ref([]);

const chatOpened = computed(() => {
  return channelId => {
    return openedChannels.value.includes(channelId);
  };
});

function repositionTiles() {
  setTimeout(() => {
    let screenSize = window.innerWidth;
    let getRightSideBarWidth = 32;
    let screenAfterMinusRightBar = screenSize - getRightSideBarWidth;
    let allowTiles = parseInt(screenAfterMinusRightBar / 328);
    selectedChannels.value.forEach((channel, index) => {
      let i = index + 1;
      if (i == 1 && i <= allowTiles) {
        document.getElementById(`chat_tile_${channel.cid}`).style.right = `${getRightSideBarWidth}px`;
        document.getElementById(`chat_tile_${channel.cid}`).parentElement.classList.remove('hidden');
      } else if (i <= allowTiles) {
        let rightMargin = index * 328;
        document.getElementById(`chat_tile_${channel.cid}`).style.right = `${getRightSideBarWidth + rightMargin}px`;
        document.getElementById(`chat_tile_${channel.cid}`).parentElement.classList.remove('hidden');
      } else {
        document.getElementsByClassName('chatTile')[0].remove();
        selectedChannels.value.splice(0, 1);
      }
    });
  });
}

function openChannel(channel) {
  if (!selectedChannels.value.some(c => c.cid === channel.cid)) {
    selectedChannels.value.push(channel);
    if (
      !localStorage.getItem('closedChats') ||
      !JSON.parse(localStorage.getItem('closedChats')).includes(channel.cid)
    ) {
      openedChannels.value.push(channel.cid);
    }
  }
}

function setChatOpenState(channelCId) {
  if (openedChannels.value.includes(channelCId)) {
    let channelIndex = openedChannels.value.findIndex(channelId => channelId === channelCId);
    if (channelIndex !== -1) {
      openedChannels.value.splice(channelIndex, 1);
    }
  } else {
    openedChannels.value.push(channelCId);
  }
}

function openStoredChats() {
  let channelsData = JSON.parse(localStorage.getItem('selectedChats'));
  channelsData.forEach(channelCid => {
    let channelRecord = channels.value.find(x => x.cid === channelCid);
    if (channelRecord) {
      openChannel(channelRecord);
    }
  });
}

function closeChat(channelCid) {
  selectedChannels.value = selectedChannels.value.filter(c => c.cid !== channelCid);
  let channelIndex = openedChannels.value.findIndex(channelId => channelId === channelCid);
  if (channelIndex !== -1) {
    openedChannels.value.splice(channelIndex, 1);
  }
  if (localStorage.getItem('selectedChats')) {
    let items = JSON.parse(localStorage.getItem('selectedChats'));
    localStorage.setItem('selectedChats', JSON.stringify(items.filter(i => i !== channelCid)));
  }
}

watch(
  () => openedChannels.value,
  () => {
    if (channels.value.length) {
      localStorage.setItem(
        'closedChats',
        JSON.stringify(selectedChannels.value.filter(i => !openedChannels.value.includes(i.cid)).map(c => c.cid))
      );
    }
  },
  { deep: true, immediate: true }
);

watch(
  () => selectedChannels.value,
  () => {
    repositionTiles();
  },
  { deep: true, immediate: true }
);

onMounted(() => {
  $emitter.$on('channel:selected', channel => {
    openChannel(channel);
  });
});

// EVENTS
function onResize() {
  repositionTiles();
}

function keydownEvent(e) {
  if (e.target.nodeName.toLowerCase() === 'textarea') {
    return;
  }
  if (e.key === 'Escape') {
    opened.value = false;
  }
}
onMounted(() => {
  window.addEventListener('resize', onResize);
  window.addEventListener('keydown', keydownEvent);
});

// OTHERS
const showGifSelector = ref(null);
const showMessagingButton = ref(false);

watch(
  () => channels.value,
  () => {
    if (localStorage.getItem('selectedChats') && channels.value.length) {
      openStoredChats();
    }
  }
);
async function sendMessage(payload, id) {
  let messageObject = {
    attachments: [
      {
        type: 'giphy',
        thumb_url: `https://media.giphy.com/media/${payload.gif.id}/giphy.gif`,
        title: payload.gif.title,
        title_link: payload.gif.url,
        giphy: {}
      }
    ],
    quoted_message_id: null,
    command: 'giphy',
    command_info: { name: 'Giphy' }
  };
  messageObject.attachments.forEach(attachment => {
    ['original', 'fixed_width', 'fixed_height'].forEach(img => {
      attachment.giphy[img] = {
        url: String(payload.gif.images[img].url),
        height: String(payload.gif.images[img].height),
        width: String(payload.gif.images[img].width)
      };
    });
  });
  let channel = channels.value.find(c => c.cid === id);
  channel.sendMessage(messageObject);
}

function closeDropdown() {
  opened.value = false;
  $emitter.$emit('chat-dropdown-toggle', opened.value);
}

function openMessenger() {
  router.visit('/messenger');
}

function openMessaging(channelId = null) {
  if (channelId) {
    window.open(`/messenger?channel=${channelId}`);
  } else {
    window.open(`/messenger`, '_blank');
  }
}

function channelName(channel) {
  return getChannelName(channel);
}

onBeforeUnmount(() => {
  $emitter.$off('channel:selected');
  $emitter.$off('channel-removed');
  window.removeEventListener('resize', onResize);
  window.removeEventListener('keydown', keydownEvent);
});
</script>
